import Block from "@/components/wrappers/block"
import styles from "./button.module.css"
import Button from "@/common/button/button"
import { trackProductClick } from "@/lib/helpers/tagManager"
import classNames from "classnames/bind"
import { parse } from "next-useragent"
import InApp from "detect-inapp"

export default function CoreButton({ block, tracking, settings = {} }) {
  if (!block.attrs.url) {
    return null
  }

  const cx = classNames.bind(styles)
  const blockClasses = {
    button: true,
    "has-width": block.attrs.width,
    "width-25": block.attrs.width === 25,
    "width-50": block.attrs.width === 50,
    "width-75": block.attrs.width === 75,
    "width-100": block.attrs.width === 100,
    "multiple-wrap": settings?.layout?.flexWrap === "wrap" && settings.multiple,
    "toggle-naturkontakt-modal": block?.attrs?.naturkontaktSSO
  }

  const handleClick = (e) => {
    trackProductClick(tracking, "gift")
    const ua = parse(window.navigator.userAgent)
    const inApp = new InApp(window.navigator.userAgent)

    if (block?.attrs?.uaConditional && ua.isIos && inApp.isInApp) {
      e.preventDefault()
    }
  }

  return (
    <Block block={block} noStyling={true} className={cx(blockClasses)}>
      <Button
        tagName="a"
        theme={block.attrs.theme}
        href={block.attrs.url}
        target={block.attrs.target}
        onClick={(e) => handleClick(e)}>
        {block.text}
      </Button>
    </Block>
  )
}
